<template>
  <NavBar/>
 <LoginForm></LoginForm>
</template>

<script>
import LoginForm from '@/components/Login/LoginForm.vue';
import NavBar from '@/components/NavBar.vue';

export default {
  name: 'Login',
  components: { NavBar, LoginForm },
};
</script>

<style scoped>

</style>
