<template>
  <div id="LoginForm">
    <!-- bootstrap responsive card -->
    <div class="card">
      <div class="card-header">
        <h3>Login</h3>
      </div>
      <div class="card-body">
        <form @submit.prevent="login">
          <div class="form-group">
            <label for="email">Email</label>
            <input type="text" class="form-control" id="email"
                   placeholder="Email" v-model="state.email" required>
            <span v-if="v$.email.$error">
              {{v$.email.$errors[0].$message}}
            </span>
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input type="password" class="form-control" id="password" v-model="state.password"
                   placeholder="Password" required>
            <span v-if="v$.password.$error">
              {{v$.password.$errors[0].$message}}
            </span>
          </div>
          <button type="submit" class="btn btn-primary" v-on:click="login">Login</button>
          <button type="button" class="btn btn-secondary" v-on:click="Register">Register</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { computed, reactive } from 'vue';
import { email, minLength, required } from '@vuelidate/validators';
import { useToast } from 'vue-toastification';

export default {
  setup() {
    const toast = useToast();
    const state = reactive({
      email: '',
      password: '',
    });

    const rules = computed(() => ({
      email: [required, email],
      password: [required, minLength(5)],
    }));

    const v$ = useVuelidate(rules, state);

    return {
      state,
      v$,
      toast,
    };
  },
  name: 'LoginForm',
  methods: {
    async login() {
      this.v$.$validate();
      this.axios.post(`https://${this.$store.state.ip}/login`, {
        email: this.state.email,
        password: this.state.password,
      })
        .then((response) => {
          this.$store.dispatch('setToken', response.data.token);
          this.getUserInfo();
          this.$store.dispatch('setRefreshToken', response.data.refresh_token);
          this.successToast(response.data.message);
        })
        .catch((error) => {
          this.errorToast(error.response.data.error);
        });
    },
    async getUserInfo() {
      await this.axios.get(`https://${this.$store.state.ip}/get-user-info`, {
        headers: {
          Authorization: this.$store.state.token,
        },
      })
        .then((response) => {
          this.$store.dispatch('setUser', response.data.username);
        })
        .catch((error) => {
          this.errorToast(error.response.data.error);
        });
    },
    Register() {
      this.$router.push('/register');
    },
    successToast(message) {
      if (this.successToastBoolean === false) {
        this.successToastBoolean = true;
        this.toast.success(message, {
          autoHideDelay: 5000,
          closeOnClick: true,
        });
        this.$router.push('/');
      }
    },
    errorToast(message) {
      if (this.errorToastBoolean === false) {
        this.errorToastBoolean = true;
        this.toast.error(message, {
          autoHideDelay: 5000,
          closeOnClick: true,
        });
      }
    },
  },
  data() {
    return {
      errorToastBoolean: false,
      successToastBoolean: false,
    };
  },
};
</script>

<style scoped>

#LoginForm {
  font-family: 'Uni Sans Heavy',serif;
  font-size: 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  min-height: 100vh;
}
.card{
  width: 25rem;
}
.btn-primary {
  width: 100%;
  margin-top: 20px;
  font-size: 20px;
}
.btn-secondary {
  margin-top: 10px;
  width: 100%;
  font-size: 20px;
  font-weight: lighter;
}
.card-header {
  text-align: center;
}
.form-group {
  margin-bottom: 20px;
}

</style>
